<!--
 * @Description: 车辆用户管理系统 运维管理 优惠规则 cuDiscountRule
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="规则名称">
              <el-input v-model="searchForm.activityRuleName"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规则类型"
                          label-width="90px">
              <el-select clearable
                         v-model="searchForm.discountType"
                         placeholder="请选择优惠规则类型">
                <el-option v-for="item in discountTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="有效时间"
                          label-width="90px">
              <el-date-picker v-model="times"
                              type="daterange"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button @click="query"
                     type="primary">查 询</el-button>
          <el-button @click="reset"
                     type="info"
                     style="margin-left:17px">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>优惠规则</span>
        <el-button class="tableTitleButton"
                   type="primary"
                   @click="addRule">
          新 增
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <!-- <template slot="isFirst"> -->
          <!-- <el-table-column type="selection"></el-table-column> -->
          <!-- </template> -->
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="6">
                    <el-button @click="detailsClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button @click="update(scope.row)"
                               type="text"
                               size="medium">编辑</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button @click="deleteRule(scope.row)"
                               type="text"
                               size="medium">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增、编辑弹窗 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="distributeVisible"
               append-to-body
               @close="popClose">
      <el-form ref="popForm"
               :model="popForm"
               :rules="popRules"
               label-width="120px">
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="activityRuleName"
                          label="规则名称">
              <el-input v-model="popForm.activityRuleName"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="discountType"
                          label="优惠类型">
              <el-select clearable
                         :disabled="dialogType===2"
                         v-model="popForm.discountType"
                         @change="discountTypeChange"
                         placeholder="请选择优惠类型">
                <el-option v-for="item in discountTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12">
            <el-form-item prop="issuedQuantity"
                          label="发放数量">
              <el-input v-model="popForm.issuedQuantity"
                        clearable
                        :readonly="dialogType===2"> <template slot="append">份</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="fullUse"
                          label="满额使用">
              <el-input v-model="popForm.fullUse"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="popRow">
          <el-col :span="12"
                  v-if="!popForm.discountType">
            <el-form-item label="折扣/面额">
              <el-input v-model="popForm.discount"
                        clearable
                        placeholder="请选择支付类型"
                        readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="popForm.discountType==='1'">
            <el-form-item prop="discount"
                          label="面额">
              <el-input v-model="popForm.discount"
                        clearable
                        :readonly="dialogType===2"
                        oninput="value=value.replace(/[^\d]/g,'');"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="popForm.discountType==='0'">
            <el-form-item prop="discount"
                          label="折扣">
              <el-input v-model="popForm.discount"
                        clearable
                        :readonly="dialogType===2"
                        oninput="value=value.replace(/[^\d]/g,'');"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封顶金额"
                          v-if="!popForm.discountType">
              <el-input v-model="popForm.discount"
                        clearable
                        readonly></el-input>
            </el-form-item>
            <el-form-item label="封顶金额"
                          v-if="popForm.discountType==='1'">
              <el-input v-model="popForm.discount"
                        clearable
                        readonly></el-input>
            </el-form-item>
            <el-form-item prop="cappedMoney"
                          label="封顶金额"
                          v-if="popForm.discountType==='0'">
              <el-input v-model="popForm.cappedMoney"
                        clearable
                        :readonly="dialogType===2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="receiveCount"
                      label="领券数量"
                      class="popRow">
          <el-input v-model="popForm.receiveCount"
                    clearable
                    :readonly="dialogType===2"></el-input>
        </el-form-item>
        <el-row class="time  popRow">
          <el-row>
            <el-col :span="10">
              <el-form-item label="有效期"
                            class="popRow">
                <el-radio-group v-model="radio"
                                @change="timeChange"
                                ref="radio"
                                :disabled="dialogType===2">
                  <el-radio label="1">固定日期</el-radio>
                  <el-radio label="2">领取后，当天生效，有效天数</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="popDateTimes"
                            class="line"
                            v-if="radio==='1'">
                <el-date-picker v-model="popForm.popDateTimes"
                                type="daterange"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                :picker-options="pickerOptions"
                                :readonly="dialogType===2" />
              </el-form-item>
              <el-form-item prop="expireDay"
                            class="line"
                            v-if="radio==='2'">
                <el-select clearable
                           :disabled="dialogType===2"
                           v-model="popForm.expireDay"
                           placeholder="请选择天数">
                  <el-option v-for="item in days"
                             :key="item.code"
                             :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="useDes"
                        label="使用说明">
            <el-input v-model="popForm.useDes"
                      clearable
                      type="textarea"
                      :rows="5"
                      :readonly="dialogType===2"></el-input>
          </el-form-item>
        </el-row>
        <el-form-item class="popRow"
                      v-if="dialogType==0 ||dialogType==1">
          <el-button type="primary"
                     :disabled="disabled"
                     @click="submitForm">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 详情弹窗 -->
    <el-dialog class="dialog"
               title="优惠规则详情"
               width="40%"
               :visible.sync="detailsVisible"
               append-to-body
               @close="popClose">
      <el-row class="textOneTil">基础信息</el-row>
      <el-row class="popRow details-content">
        <el-row class="content">
          <el-col class="textOne"
                  v-if="popForm.discountType==='0'"
                  :span="6">{{popForm.discount}}折折扣券</el-col>
          <el-col class="textOne"
                  v-if="popForm.discountType==='1'"
                  :span="7">满{{popForm.fullUse}}减{{popForm.discount}}优惠券</el-col>
          <el-col :span="17">
            <el-tag effect="dark">
              {{popForm.discountTypeName}}
            </el-tag>
          </el-col>
          <el-col class="textTwo"
                  :span="5">发放数量：</el-col>
          <el-col class="textThree"
                  :span="5">{{popForm.issuedQuantity}}（份）</el-col>
          <el-col class="textTwo"
                  :span="4">满额使用：</el-col>
          <el-col class="textThree"
                  :span="6">{{popForm.fullUse}}</el-col>
          <el-col class="textTwo"
                  :span="5">封顶金额：</el-col>
          <el-col class="textThree"
                  :span="5">{{popForm.cappedMoney}}</el-col>
          <el-col class="textTwo"
                  v-if="popForm.discountType==='0'"
                  :span="4">折扣：</el-col>
          <el-col class="textTwo"
                  v-if="popForm.discountType==='1'"
                  :span="4">面额：</el-col>
          <el-col class="textThree"
                  :span="6">{{popForm.discount}}</el-col>
          <el-col class="textTwo"
                  :span="5">领券数量：</el-col>
          <el-col class="textThree"
                  :span="16">{{popForm.receiveCount}} </el-col>
        </el-row>
      </el-row>
      <el-row class="popRow">
        <el-col class="textTwo"
                :span="2">有效期：</el-col>
        <el-col class="textThree"
                v-if="popForm.timeType==='0'"
                :span="22">{{ popForm.startTime }} - {{ popForm.endTime }}</el-col>
        <el-col class="textThree"
                v-if="popForm.timeType==='1'"
                :span="22">{{ popForm.expireDay }} 天</el-col>
      </el-row>
      <el-row class="popRow">
        <el-col class="textTwo"
                :span="2">使用说明：</el-col>
        <el-col class="textThree"
                :span="22">{{popForm.useDes}}</el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      tableColumnList: [
        {
          prop: 'activityRuleName',
          label: '规则名称',
        },
        {
          prop: 'discountTypeName',
          label: '规则类型',
        },
        {
          prop: 'startTime',
          label: '开始时间',
        },
        {
          prop: 'endTime',
          label: '结束时间',
        },
        {
          prop: 'discountAll',
          label: '面额/折扣',
        },
        {
          prop: 'issuedQuantity',
          label: '发放数量',
        },
        {
          prop: 'useDes',
          label: '使用说明',
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {
      },
      pageNum: 1,
      pageSize: 15,
      //有效时间
      times: [],
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // 弹出框标题
      dialogTitle: '',
      // 弹出框类型 0 新增，1编辑，2详情
      dialogType: null,
      // 弹出框是否打开
      distributeVisible: false,
      // 弹出框表单
      popForm: {
      },
      //弹出框有效日期
      popDateTimes: [],
      // 弹出框有效天数
      days: [],
      popRules: {
        activityRuleName: [
          { required: true, message: '请输入优惠规章名称', trigger: 'blur' }
        ],
        discountType: [
          { required: true, message: '请选择优惠类型', trigger: 'change' }
        ],
        issuedQuantity: [
          { required: true, message: '请输入发放数量', trigger: 'blur' }
        ],
        fullUse: [
          { required: true, message: '请输入满额使用金额', trigger: 'blur' }
        ],
        discount: [
          { required: true, message: '请输入面额或折扣', trigger: 'blur' }
        ],
        cappedMoney: [
          { required: true, message: '请输入封顶金额', trigger: 'blur' }
        ],
        receiveCount: [
          { required: true, message: '请输入领券数量', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '请选择有效期', trigger: 'change' }
        ],
        useDes: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ],
        popDateTimes: [
          { required: true, message: '请选择有效日期', trigger: 'change' }
        ],
        expireDay: [
          { required: true, message: '请选择有效天数', trigger: 'change' }
        ]
      },
      discountTypeList: [],//优惠类型
      //新增中面额、折扣切换
      discountTypeCode: null,
      // 有效期
      radio: '1',
      //详情弹窗
      detailsVisible: false,
      disabled: false  //禁用按钮
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getDiscountTypeList()
  },
  //方法集合
  methods: {
    // 优惠规则类型下拉框 
    getDiscountTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABJKFSHGRFOUIGHLFCNFFDJDLIFHRFO',
          },
        ],

      }
      this.$queryDict.queryDict(info).then((response) => {
        this.discountTypeList = response.resultEntity
        this.getRuleList()
      })

    },
    //列表
    getRuleList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$cuDiscountRule.getList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.tableList.list.forEach(item => {
          if (item.discountType == 0) {
            item['discountAll'] = 0 + '-' + item.discount
          } else {
            item['discountAll'] = item.discount + '-' + item.discount
          }
          //将优惠类型编码转汉字
          this.discountTypeList.forEach(element => {
            if (item.discountType == element.code) {
              item['discountTypeName'] = element.name
            }
          })
        })
        this.paginationConfig.total = res.resultEntity.total
      }).catch()
    },
    //查询
    query () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.times) {
        this.searchForm.startTime = this.times[0]
        this.searchForm.endTime = this.times[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.getRuleList()
    },
    // 重置
    reset () {
      this.searchForm = {}
      this.times = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.getRuleList()
    },
    // 新增按钮
    addRule () {
      this.dialogType = 0
      this.dialogTitle = '优惠规则新增'
      this.distributeVisible = true
      if (this.$refs.popForm) {
        this.$refs.popForm.clearValidate()
      }
    },
    //监听弹窗优惠类型
    discountTypeChange (val) {
      console.log(val);
    },
    // 监控有效期选项
    timeChange (val) {
      this.radio = val
      if (this.radio == 1) {
        this.$delete(this.popForm, 'expireDay')
      } else if (this.radio == 2) {
        this.$delete(this.popForm, 'popDateTimes')

      }
    },
    // 点击编辑
    update (row) {
      this.popForm = this.$deepClone(row)
      console.log(this.popForm, 'this.popForm111111111111');
      this.dialogTitle = '优惠规则修改'
      if (this.$refs.popForm) {
        this.$refs.popForm.clearValidate()
      }
      this.dialogType = 1
      if (this.popForm.timeType === "0") {
        this.radio = '1'
        this.popForm.popDateTimes = [this.popForm.startTime, this.popForm.endTime]
      } else if (this.popForm.timeType === "1") {
        this.radio = '2'
      }
      this.distributeVisible = true
    },
    // 保存按钮
    submitForm () {
      this.disabled = true
      this.$refs.popForm.validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.addRequest()
          } else if (this.dialogType === 1) {
            this.updateRequest()
          }
        } else {
          return false;
        }
      });
      setTimeout(() => {
        this.disabled = false;
      }, 1000)

    },
    // 新增请求
    addRequest () {
      if (this.radio === '1') {
        this.popForm.startTime = this.popForm.popDateTimes[0]
        this.popForm.endTime = this.popForm.popDateTimes[1]
      }
      if (this.popForm.discountType === '1') {
        this.popForm.cappedMoney = this.popForm.discount
      }
      this.$cuDiscountRule.add(this.popForm).then(() => {
        this.$message(
          {
            message: '新增优惠规则成功',
            type: 'success'
          }
        )
        this.distributeVisible = false
        this.getRuleList()
      }).catch(() => {
        this.$message.error('新增失败')
      })
    },
    // // 修改请求
    updateRequest () {
      let info = {
        activityRuleId: this.popForm.activityRuleId,
        activityRuleName: this.popForm.activityRuleName,
        cappedMoney: this.popForm.cappedMoney,
        discount: this.popForm.discount,
        discountType: this.popForm.discountType,
        fullUse: this.popForm.fullUse,
        expireDay: this.popForm.expireDay,
        issuedQuantity: this.popForm.issuedQuantity,
        receiveCount: this.popForm.receiveCount,
        useDes: this.popForm.useDes
      }
      if (this.radio === '1') {
        info.startTime = this.popForm.popDateTimes[0]
        info.endTime = this.popForm.popDateTimes[1]
      }
      if (this.popForm.discountType === '1') {
        info.cappedMoney = this.popForm.discount
      }
      this.$cuDiscountRule.update(info).then(() => {
        this.$message(
          {
            message: '优惠规则修改成功',
            type: 'success'
          }
        )
        this.distributeVisible = false
        this.getRuleList()
      }).catch(() => {
        this.$message.error('修改失败')
      })
    },
    // 点击详情
    detailsClick (row) {
      this.popForm = this.$deepClone(row)
      console.log(this.popForm, 'this.popForm');
      this.detailsVisible = true
    },
    // 点击删除
    deleteRule (row) {
      this.$confirm('是否确定删除该优惠规则?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cuDiscountRule.deleteRule({ activityRuleId: row.activityRuleId }).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          // 删除成功后获取表格
          this.getRuleList()
        })
      }).catch()
    },
    // 弹出层关闭事件
    popClose () {
      this.popForm = {}
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getRuleList()
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    for (let i = 1; i <= 30; i++) {
      this.days.push(i)
    }
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainbody {
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
        margin-left: 17px;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.search_column2 {
  margin-top: 20px;
}
.popRow {
  margin-bottom: 20px;
}
/deep/.el-input-group__append {
  border: solid 1px#004474FF;
  background: #051835ff;
  color: #70aee8;
  padding: 0 8px;
}
.time {
  /deep/.el-form-item__content {
    width: 675px !important;
  }
  .line {
    /deep/.el-form-item__content {
      width: 295px !important;
    }
  }
}
.details-content {
  height: 164px;
  width: 448px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
  background: #122849;
  .content {
    margin-bottom: 15px;
    .el-col {
      padding-top: 15px;
    }
  }
}
.textOneTil {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #f8fdff;
}
.textOne {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #f8fdff;
  margin-top: 4px;
}
.textTwo {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #70aee8;
}
.textThree {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #bae8ff;
}
</style>

